<template>
    <b-row class="match-height">
      <b-col lg="6"> <b-card
      title="Receive Material Request"
    >
      <b-form
          @submit.prevent="poCheck">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="No Material Request *"
              label-for="no_material_request"
              label-cols-md="4"
            >
              <b-form-input
                id="no_material_request"
                placeholder="No Material Request"
                required="required"
                v-model="MaterialRequestNumber"
              />
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BModal, BButton, VBModal, BCard,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'

  const MaterialRequestNumber = ''
  const submitButtonDisabled = false
  export default {
    components: {
      BModal,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      'b-modal': VBModal,
    },
    data() {
      return {
      MaterialRequestNumber,
      submitButtonDisabled,
      }
    },
    methods: {
    poCheck() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const body = {
            material_request_number: this.MaterialRequestNumber,
        }
        const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_VALIDATION}`
      axios
        .post(url, body, { headers })
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            if (this.hasPermission('material_request_receive') === true && response.data.data.material_request_is_received === false) {
                localStorage.setItem('MaterialRequestDetail', JSON.stringify(response.data.data))
                this.$router.push({ name: 'apps-material-request-receive-form' })
            } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You Do not have permission to do this',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
            }
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invoice Number Invalid!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    hasPermission(permission) {
      const permissions = this.$cookies.get('userPermissions').split(',')
      return permissions.includes(permission) ? true : false
    },
    },
  }
  </script>
